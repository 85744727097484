import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, repeatWhen, takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { NgOtpInputComponent } from 'ng-otp-input';

interface DialogData {
  email: string;
}
const BORDER = '1px solid #FAFAFA';
@Component({
  selector: 'app-verify-email-alert',
  templateUrl: './verify-email-alert.component.html',
  styleUrls: ['./verify-email-alert.component.scss'],
})
export class VerifyEmailAlertComponent implements OnInit {
  email: string;
  form: FormGroup;
  config = {
    allowNumbersOnly: true,
    length: 6,
    disableAutoFocus: false,
    inputStyles: {
      border: BORDER,
      background: '#FAFAFA',
      color: '#006879',
      'font-size': '15px',
      'font-weight': 'bold',
    },
  };

  counter: number = 120; // 2 minute = 120 second
  private readonly _startTimer = new Subject<void>();
  private readonly _stop = new Subject<void>();
  timer$ = timer(0, 1000).pipe(
    map((i) => {
      if (this.counter - i < 0) this._stop.next();
      return this.counter - i;
    }),
    takeUntil(this._stop),
    repeatWhen(() => this._startTimer)
  );

  isReSend: boolean;
  isSending: boolean;
   @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput: NgOtpInputComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private fb: FormBuilder,
    private userService: UserService,
    private registrationService: RegistrationService,
    public dialogRef: MatDialogRef<VerifyEmailAlertComponent>,
  ) {
    this.email = dialogData.email;
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/imgs/profile-icon-set.svg'
      )
    );
  }

  onOtpChange(code: string) {
    this.form.patchValue({
      token: code,
    });
  }
  onSubmit() {
    let data = {
      "email" : this.email,
      "code": this.form.get('token').value,
    }
    this.registrationService.verifyEmail({data}).subscribe((res) => {
      this.dialogRef.close()
    })
  }

  initForm() {
    this.form = this.fb.group({
      token: [null, [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit(): void {
    if (!this.isReSend) {
      setTimeout(() => {
        this.isReSend = true;
      }, 120000);
    }
    this.initForm();
  }

  async resendVerificationCode(): Promise<void> {
    this.isSending = true;
    this.userService.sendEmailOtp().subscribe(
      (res) => {
        this.isReSend = false;
        this.isSending = false;
        setTimeout(() => {
          this.isReSend = true;
        }, 120000);
        this.ngOtpInput.otpForm.reset();
        this.form.get('token').patchValue('');
        this.config.inputStyles.border = BORDER;
        this._stop.next();
        this._startTimer.next();
      }
    );
  }
}
