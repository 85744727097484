import { BaseCrudService } from './base-crud.service';
import { Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseCrudService<null> {
  constructor(injector: Injector) {
    super(injector);
    this.path = environment.auth_url;
  }

  searchUser(data: {}): Observable<User[]> {
    return this.requestService.getJSON<User[]>(this.path + '/users/search', {
      data,
    });
  }

  updateInformation(data: User) {
    return this.requestService.putJSON(this.path + '/me', { data });
  }

  changePassword(data: {}) {
    return this.requestService.postJSON(this.path + '/me/change-password', {
      data,
    });
  }

  editProfileInfo(data: {}) {
    return this.requestService.putJSON(
      environment.portal_url + '/profile' + '/about',
      { data }
    );
  }

  getUserFavorites(data: any = {}) {
    return this.requestService.getJSON(environment.portal_url + '/favorites', {
      data,
    });
  }

  updateEmailRequest(email: string, lang: string) {
    return this.requestService.postJSONWithLang(this.path + "/update-email-request", { data: { email } }, lang);
  }

  verifyEmailOtp(data: { email: string, otp: string }) {
    return this.requestService.postJSON(this.path + "/verify-update-email-request", { data });
  }
  sendEmailOtp(){
    return this.requestService.postJSON(this.path + "/user/verify-email",{});
  }
}
