<div mat-dialog-title class="dialog-header">
  <h3 class="mat-h3">
      {{ 'ProfilePage.verifyEmail.header' | translate }}
  </h3>
</div>
<mat-divider class="mb-16"></mat-divider>
<mat-dialog-content class="overflow-hidden pb-10 verity-email-otp" align="center">
  <form id="verify-email-otp-form" [formGroup]="form" class="dialog-form" autocomplete="off">

      <div>
          <mat-icon svgIcon="verify-email" class="!w-150 !h-150"></mat-icon>
          <div class="mb-16 text-16 font-bold text-secondary">{{ 'ProfilePage.verifyEmail.title' | translate }}</div>
          <div class="text-grey text-12">{{ 'ProfilePage.verifyEmail.receiveCodeText' | translate }}</div>
          <div class="mb-22 text-primary font-semibold">{{ email }}</div>
      </div>

      <div class="form-container">
          <ng-otp-input [config]="config" (onInputChange)="onOtpChange($event)"></ng-otp-input>
      </div>

      <div class="mt-20">
          <span class="text-grey">{{ 'ProfilePage.verifyEmail.noCodeReceivedText' | translate }}&nbsp;&nbsp;</span>
          <a *ngIf="isReSend; else countdown" class="txt-resend text-secondary"
              (click)="!isSending ? resendVerificationCode() : $event.stopPropagation()">
              {{ 'ProfilePage.verifyEmail.reSend' | translate }}
          </a>

          <ng-template #countdown>
              <span *ngIf="timer$" class="text-secondary font-semibold">
                  {{(timer$ | async) * 1000 | date:'mm:ss':'UTC'}} {{ "Time.Seconds" | translate }}
              </span>
          </ng-template>
      </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-center mb-0">
  <button form="verify-email-otp-form" type="submit" mat-flat-button color="primary" class="btn-md"
      [disabled]="form.invalid" (click)="onSubmit()">
      {{ 'ActionBtn.Continue' | translate }}
      <mat-icon class="svg-path svg-fill" svgIcon="right-arrow"></mat-icon>
  </button>
</mat-dialog-actions>