<div class="containers">
  <h3 class="mat-h3">{{ "ProfilePage.yourAccountText" | translate }}</h3>
  <div class="rounded-15 p-12 mr-10">
    <div class="flex align-items-center justify-content-space-between">
      <app-profile-image [routerLink]="['/profile', userId]"
        [profileImage]="profile?.photo_url ? profile?.photo_url + '?token=' + token : 'assets/imgs/avatar-person.svg'"
        [fullName]="profile | fullName : ''" classCaption="caption-x-sm-ellipses" className="title-md font-bold"
        [profileStyle]="{ width: '34px', height: '34px' }"
        style="width: 80%;"
        [isCamdxUser]="profile?.camdx_user_id != null"
      >
      </app-profile-image>
      <div *ngIf="profile?.shops.length > 0" class="swap-img" (click)="linkUserAndStoreMode()">
        <mat-icon class="icon-swap-home" svgIcon="icon-swap-home">
          <app-profile-image class="profile-images"
            [profileImage]="profile?.shops[0]?.photo_url ? profile?.shops[0]?.photo_url + '?token=' + token : 'assets/imgs/official-store.svg'"
            [profileStyle]="{ width: '24px', height: '24px' }">
          </app-profile-image>
        </mat-icon>
      </div>
    </div>

    <ng-container *ngIf="profile && !profile?.email">
      <mat-divider class="mt-10"></mat-divider>
      <div>
        <div class="flex text-warn text-12">
          <mat-icon svgIcon="mail"></mat-icon>
          <span class="ml-4">
            {{ "ProfilePage.noEmailTitle" | translate }}
          </span>
        </div>
        <div class="text-white text-12 pt-4">{{ "ProfilePage.noEmailMessage" | translate }}</div>
      </div>
    </ng-container>
  </div>
  <h3 class="mt-20">{{ "Menu.Setting" | translate }}</h3>
  <mat-action-list class="sl-list pr-10">
    <button mat-list-item routerLink="update-profile" routerLinkActive="active">
      <mat-icon class="svg-path svg-fill" svgIcon="icon-username"> </mat-icon>
      <p class="title">{{ "ProfilePage.detail" | translate }}</p>
    </button>
    <button mat-list-item routerLink="favorite" routerLinkActive="active">
      <mat-icon class="svg-path svg-fill" svgIcon="icon-favorite"></mat-icon>
      <p class="title">{{ "Menu.Save" | translate }}</p>
    </button>
    <button mat-list-item routerLink="blocked-user" routerLinkActive="active">
      <mat-icon class="svg-path svg-fill" svgIcon="icon-block"></mat-icon>
      <p class="title">{{ "Menu.BlockedUser" | translate }}</p>
    </button>
    <button *ngIf="isLoginWithPassword" mat-list-item routerLink="change-password" routerLinkActive="active">
      <mat-icon class="svg-path svg-fill" svgIcon="icon-change-password"></mat-icon>
      <p class="title">{{ "Menu.ChangePassword" | translate }}</p>
    </button>
    <button mat-list-item routerLink="delete-own-account" routerLinkActive="active">
      <mat-icon class="svg-path svg-fill" svgIcon="icon-delete"></mat-icon>
      <p class="title">{{ "Menu.deleteOwnAccount" | translate }}</p>
    </button>
  </mat-action-list>
</div>