import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router, RouterEvent, NavigationStart } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service'
import { Subscription } from 'rxjs'
import { Chat } from 'src/app/models/chat'
import { LocalStorageService } from 'src/app/services/local-storage.service'
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum'
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() onMenuClicked = new EventEmitter();
  @Output() onSearchEmitter = new EventEmitter();
  @Input() keyword: string = '';
  @Input() searchLayout: boolean = false;
  @Input() chat: Chat
  showSearch: boolean = true;

  isExpanded = true;
  realtimeUnreadMsgCountSubscription: Subscription
  unreadMessageCountSubscription: Subscription
  unreadMsgCount: number = 0
  userId: string

  constructor(
    private router: Router,
    private chatService: ChatService,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.onSmallScreen();
    // this.router.events.subscribe((event: RouterEvent) => {
    //   this.showSearch = event.url === '/home' || this.searchLayout === true;
    // });

    // this.showSearch = this.router.url === '/home' || this.searchLayout === true;

    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        // Close the search box when navigating away from the pages where it should be open
        if (this.showSearch && this.isSearchPage(event.url)) {
          this.showSearch = true;
        }
      }
    });
    if (this.unreadMessageCountSubscription) {
      this.unreadMessageCountSubscription.unsubscribe();
    }

    this.getUnreadMessageCount();
    this.unreadMessageCountSubscription = this.chatService.unreadMsgCountChange
      .pipe()
      .subscribe(this.onUnreadMessageCountChange.bind(this));
    this.userId = this.localStorageService.get(LocalStorageEnum.user_id);
    this.realtimeUnreadMsgCountSubscription = this.chatService
      .subscribeToRealtimeUnreadMsgCount(this.userId)
      .pipe()
      .subscribe(this.onRealtimeUnreadMsgCountChange.bind(this))
  }

  ngOnDestroy(): void {
    if (this.unreadMessageCountSubscription) {
      this.unreadMessageCountSubscription.unsubscribe();
    }

    if (this.realtimeUnreadMsgCountSubscription) {
      this.realtimeUnreadMsgCountSubscription.unsubscribe();
    }
  }

  getUnreadMessageCount() {
    this.chatService.getUserUnreadMessageCount();
  }

  onUnreadMessageCountChange(value: any) {
    this.unreadMsgCount = Number(value.data.unread_messages_count);

    this.unreadMessageCountSubscription.unsubscribe();
  }

  onRealtimeUnreadMsgCountChange(value: any) {
    this.unreadMsgCount = Number(value.data.unread_messages_count);
  }

  toggleSearchForm() {
    this.showSearch = !this.showSearch;
  }

  isSearchPage(url: string): boolean {
    return ['/home', '/sell-product', '/shop', '/announcement', '/message', '/my-order', '/kas-data-entry', '/weather-forcast'].includes(url);
  }

  onSmallScreen(): void {
    if (window.innerWidth <= 991) {
      this.isExpanded = false;
    } else {
      this.isExpanded = true;
    }
  }

  menuClickHandler() {
    this.onMenuClicked.emit();
  }

  redirectSearch() {
    // if from other pages, when search redirect to page search
    if (!this.searchLayout && this.keyword != '') {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          keyword: this.keyword
        }
      };

      this.router.navigate(['/search-all'], navigationExtras);
    } else {
      this.onSearchEmitter.emit(this.keyword);
    }

  }

  reloadComponent() {
    // When true, navigates without pushing a new state into history.
    // Navigate silently to /view
    this.router.navigate(['/view'], { skipLocationChange: true }).then(() => {
      this.router.navigate(['/home']);
    });
  }

}
