import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageEnum } from '../models/enums/local-storage.enum';
import { BaseResponse } from '../models/responses/base.response';
import { Router } from '@angular/router';
import { throwError, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { RequestOption } from '../models/request-option';
import { RequestParam } from '../models/request-param';
import { RequestError } from '../models/request-error';
import { RequestErrorEnum } from '../models/enums/request-error.enum';
@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  downloadImage(url: string, request: RequestParam = {}) {
    if (request.option?.is_loading) {
      this.loadingService.setLoading(true);
    }

    return this.http.get(url, {
      responseType: 'blob', params: request.data
    });
  }

  get<T>(path: string, request: RequestParam = {}) {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.option?.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = this.getAuthHeader();
    return this.http.get<T>(url, { params: request.data, headers }).pipe(
      map((res) => {
        if (request.option?.is_loading) {
          this.loadingService.setLoading(false);
        }
        return res;
      }),
      catchError((error) => {
        console.error(error);
        if (request.option?.is_loading) {
          this.loadingService.setLoading(false);
        }
        return throwError({ message: error.message });
      })
    );
  }

  getJSON<T>(path: string, request: RequestParam = {}) {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.option?.is_loading) {
      this.loadingService.setLoading(true);
    }

    const headers = this.getAuthHeader();
    headers.append('Content-Type', 'application/json');
    return this.http
      .get<BaseResponse>(url, { params: request.data, headers })
      .pipe(
        map((res) => this.handleResponse<T>(res, request.option)),
        catchError((err) => this.handleHttpError(err, request.option))
      );
  }
  getJSONWithLang<T>(path: string, lang: string, request: RequestParam = {}) {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.option?.is_loading) {
      this.loadingService.setLoading(true);
    }

    let headers = this.getAuthHeader();
    headers = headers.set('Content-Type', 'application/json').set('Accept-Language', lang);
    return this.http
      .get<BaseResponse>(url, { params: request.data, headers })
      .pipe(
        map((res) => this.handleResponse<T>(res, request.option)),
        catchError((err) => this.handleHttpError(err, request.option))
      );
  }

  post<T>(path: string, request: RequestParam) {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.option?.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = this.getAuthHeader();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    request.data = this.toFormData(request.data);
    return this.http.post<BaseResponse>(url, request.data, { headers }).pipe(
      map((res) => this.handleResponse<T>(res, request.option)),
      catchError((err) => this.handleHttpError(err, request.option))
    );
  }

  postJSON<T>(path: string, request: RequestParam) {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.option?.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = this.getAuthHeader();
    headers.append('Content-Type', 'application/json');
    return this.http
      .post<BaseResponse>(url, request.data, { params: request.param, headers })
      .pipe(
        map((res) => this.handleResponse<T>(res, request.option)),
        catchError((err) => this.handleHttpError(err, request.option))
      );
  }

  postJSONWithLang<T>(path: string, request: RequestParam , lang: string) {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.option?.is_loading) {
      this.loadingService.setLoading(true);
  }
    let headers = this.getAuthHeader();
    // headers.append('Content-Type', 'application/json');
    headers = headers.set('Content-Type', 'application/json').set('Lang', lang);
    return this.http
      .post<BaseResponse>(url, request.data, { params: request.param, headers })
      .pipe(
        map((res) => this.handleResponse<T>(res, request.option)),
        catchError((err) => this.handleHttpError(err, request.option))
      );
  }
  postFile<T>(path: string, request: RequestParam): Observable<T> {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.option?.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = this.getAuthHeader();
    headers.append('Content-Type', 'multipart/form-data;');
    request.data = this.toFormData(request.data);
    return this.http.post<BaseResponse>(url, request.data, { headers }).pipe(
      map((res) => this.handleResponse<T>(res, request.option)),
      catchError((err) => this.handleHttpError(err, request.option))
    );
  }

  patchFile<T>(path: string, request: RequestParam): Observable<T> {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.option?.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = this.getAuthHeader();
    headers.append('Content-Type', 'multipart/form-data;');
    request.data = this.toFormData(request.data);
    return this.http.patch<BaseResponse>(url, request.data, { headers }).pipe(
      map((res) => this.handleResponse<T>(res, request.option)),
      catchError((err) => this.handleHttpError(err, request.option))
    );
  }

  putFile<T>(path: string, request: RequestParam): Observable<T> {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.option?.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = this.getAuthHeader();
    headers.append('Content-Type', 'multipart/form-data;');
    request.data = this.toFormData(request.data);
    return this.http.put<BaseResponse>(url, request.data, { headers }).pipe(
      map((res) => this.handleResponse<T>(res, request.option)),
      catchError((err) => this.handleHttpError(err, request.option))
    );
  }

  putFileForUpdateSellProduct<T>(path: string, request: RequestParam): Observable<T> {
    const url = this.getUrl(path);
    if (request.option?.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = this.getAuthHeader();
    headers.append('Content-Type', 'multipart/form-data;');
    request.data = this.toFormData(request.data);
    return this.http.put<BaseResponse>(url, request.data, { headers }).pipe(
      map((res) => this.handleResponse<T>(res, request.option)),
      catchError((err) => this.handleHttpError(err, request.option))
    );
  }

  postFileProgress<T>(
    path: string,
    request: RequestParam
  ): Observable<number | T> {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.option?.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = this.getAuthHeader();
    headers.append('Content-Type', 'multipart/form-data;boundary=abc');
    request.data = this.toFormData(request.data);
    return this.http
      .post<BaseResponse>(url, request.data, {
        headers,
        reportProgress: true,
        responseType: 'json',
        observe: 'events',
      })
      .pipe(
        map((res) => {
          if (res.type == HttpEventType.UploadProgress) {
            return Math.round((res.loaded / res.total) * 100);
          } else if (res.type === HttpEventType.Response) {
            return this.handleResponse<T>(res.body, request.option);
          }
        }),
        catchError((err) => this.handleHttpError(err, request.option))
      );
  }

  patchJSON<T>(path: string, request: RequestParam) {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.option?.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = this.getAuthHeader();
    headers.append('Content-Type', 'application/json');
    return this.http.patch<BaseResponse>(url, request.data, { headers }).pipe(
      map((res) => this.handleResponse<T>(res, request.option)),
      catchError((err) => this.handleHttpError(err, request.option))
    );
  }

  putJSON<T>(path: string, request: RequestParam) {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.option?.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = this.getAuthHeader();
    headers.append('Content-Type', 'application/json');
    return this.http.put<BaseResponse>(url, request.data, { headers }).pipe(
      map((res) => this.handleResponse<T>(res, request.option)),
      catchError((err) => this.handleHttpError(err, request.option))
    );
  }

  put<T>(path: string, request: RequestParam) {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.option?.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = this.getAuthHeader();
    headers.append('Content-Type', 'multipart/form-data;');
    request.data = this.toFormData(request.data);

    return this.http.put<BaseResponse>(url, request.data, { headers }).pipe(
      map((res) => this.handleResponse<T>(res, request.option)),
      catchError((err) => this.handleHttpError(err, request.option))
    );
  }

  deleteJSON<T>(path: string, request: RequestParam = {}) {
    const url = this.getUrl(path);
    if (request.option?.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = this.getAuthHeader();
    headers.append('Content-Type', 'application/json');
    return this.http
      .delete<BaseResponse>(url, { headers, params: request.data })
      .pipe(
        map((res) => this.handleResponse<T>(res, request.option)),
        catchError((err) => this.handleHttpError(err, request.option))
      );
  }

  private clean(obj: any) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }

  private getAuthHeader(): HttpHeaders {
    const token = this.localStorageService.get(LocalStorageEnum.token);
    if (token) {
      return new HttpHeaders({
        Authorization: 'Bearer ' + token,
      });
    }
    return new HttpHeaders();
  }

  private handleResponse<T>(res: BaseResponse<T>, option: RequestOption) {
    if (option?.is_loading) {
      this.loadingService.setLoading(false);
    }
    return res.data;
  }

  private handleHttpError(error: HttpErrorResponse, option: RequestOption) {
    if (option?.is_loading) {
      this.loadingService.setLoading(false);
    }
    // if (error.status === 401) {
    //   this.localStorageService.delete(LocalStorageEnum.token);
    //   this.router.navigateByUrl('login');
    // }
    let reqestError: RequestError;
    if (
      error.status === RequestErrorEnum.unexpected_error ||
      option?.is_alert_error
    ) {
      this.snackBar.open(error.message, '?', {
        duration: 3000,
        horizontalPosition: 'center',
      });
    }
    if (error.status === RequestErrorEnum.user_error) {
      reqestError = {
        error_type: RequestErrorEnum[error.status],
        message: error.message,
        errors: error.error.errors ? error.error.errors : error.error
      };
    } else if (error.status === RequestErrorEnum.unautherized_error) {
      reqestError = {
        error_type: RequestErrorEnum[error.status],
        message: error.message,
        errors: error.error.errors,
      };
    } else if (error.status === RequestErrorEnum.not_found) {
      reqestError = {
        error_type: RequestErrorEnum[error.status],
        message: error.message,
        errors: error.error.errors,
      };
    } else if (error.status === RequestErrorEnum.incorrect_current_pwd) {
      reqestError = {
        error_type: RequestErrorEnum[error.status],
        message: error.message,
        errors: error.error.errors,
      };
    }

    return throwError(reqestError);
  }

  private toFormData<T>(formValue: T) {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      // formData.append(key, value);
      if (Array.isArray(value)) {
        // for(const index in value){
        //   formData.append(key + `[${index}]`, value[index]);
        //   console.log(key + `[${index}]` + ": " + value[index]);
        // }
        for (const index in value) {
          formData.append(key, value[index]);
        }
      } else {
        formData.append(key, value);
      }
    }
    return formData;
  }

  private getUrl(path: string) {
    let arr = path.split('/').filter((v) => v);
    arr.unshift(environment.api_url);
    return arr.join('/');
  }
}
