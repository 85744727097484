<div class="main-layout">
  <app-header (onMenuClicked)="menuClickHandler()"></app-header>
  <div class="wrapper">
    <mat-sidenav-container class="mat-sidenav">
      <mat-sidenav #drawer [(opened)]="isExpanded" [mode]="navbarMode">
        <div class="side-menu-wrapper">
          <app-side-menu></app-side-menu>
        </div>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="content-wrapper hidden-scrollbar-y">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>