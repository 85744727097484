<mat-toolbar class="toolbar">
  <!-- newly arrange....... -->

  <div class="header-left">
    <button mat-icon-button class="humberger-btn d-lg-none" (click)="menuClickHandler()">
      <mat-icon svgIcon="icon-menu"></mat-icon>
    </button>

    <div class="logo-box col-12" routerLink="/home" (click)="reloadComponent()">
      <img class="d-none d-lg-block" routerLink="/" src="../../../assets/imgs/logo-white.svg" alt="logo-image" />
      <img class="d-block d-lg-none" routerLink="/" src="../../../assets/imgs/logo.svg" alt="logo-image" />
      <div class="title d-none d-md-block">
        <h1 class="mat-h1" translate>AppTitle</h1>
      </div>
    </div>
  </div>
  <div class="search-component">
    <div class="search-form" [class.transit]="showSearch">
      <input [(ngModel)]="keyword" (keydown.enter)="redirectSearch()"
        placeholder="{{ 'Search' | translate }}" />
      <button (click)="redirectSearch()" class="icon-search-btn" mat-icon-button aria-label="button with icon search">
        <mat-icon class="icon-search-all" svgIcon="icon-search"></mat-icon>
      </button>
    </div>
  </div>

  <div class="spacer"></div>
  <div class="header-action col-12 col-md-2">
    <button class="d-none d-sm-block" mat-icon-button routerLink="/message">
      <mat-icon
        class="icon-chat"
        svgIcon="icon-chat"
        [matBadge]="unreadMsgCount"
        [matBadgeHidden]="unreadMsgCount == 0"
        matBadgeColor="warn"
        matBadgeSize="small"
      ></mat-icon>
    </button>
    <div class="action">
      <app-notification [routeId]="1" [isStoreMode]="false"></app-notification>
    </div>
    <app-profile-menu [isDisplayNoneMd]="true"></app-profile-menu>
  </div>
</mat-toolbar>