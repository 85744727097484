<div class="containers" *ngIf="product">
  <h3 class="mat-h3">{{ "sellProductDetailPage.Title" | translate }}</h3>
  <mat-divider></mat-divider>
  <div class="content-dialog row">
    <div class="content-left col-lg-6">
      <div class="image">
        <div class="slider">
          <div *ngIf="product?.photo_urls.length <= 0">
            <img class="default-img" src="assets/imgs/img-no-gallery.svg" />
          </div>
          <div class="app-picture-product-detail" *ngIf="product?.photo_urls.length >= 1"
            [@slideAnimation]="currentIndex">
            <ng-container *ngIf="
                product?.photo_with_size_urls &&
                  product?.photo_with_size_urls?.length;
                else elseCondition
              ">
              <div *ngFor="
                  let photo of product?.photo_with_size_urls;
                  let i = index
                ">
                <app-picture *ngIf="isCurrentSlideIndex(i)" [imgUrl]="photo" [fallbackUrl]="photo?.url"></app-picture>
              </div>
            </ng-container>
            <ng-template #elseCondition>
              <div *ngFor="let photo of product?.photo_urls; let i = index">
                <img class="p-img slide" *ngIf="isCurrentSlideIndex(i)"
                  [src]="photo | token : 'assets/imgs/img-no-gallery.svg'" />
              </div>
            </ng-template>
          </div>
          <div *ngIf="product?.photo_urls?.length > 1">
            <mat-icon color="primary" class="arrow prev" (click)="nextSlide()">arrow_back_ios</mat-icon>
            <mat-icon color="primary" class="arrow next" (click)="prevSlide()">arrow_forward_ios</mat-icon>
          </div>
          <nav class="nav" *ngIf="product?.photo_urls?.length > 1">
            <ul class="dots">
              <li class="dot" *ngFor="let photo of product?.photo_urls; let i = index">
                <span [ngClass]="{ active: isCurrentSlideIndex(i) }" (click)="setCurrentSlideIndex(i)"></span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="content-right col-lg-6">
      <div class="title-and-button">
        <div class="content-right-save-date">
          <h4 class="mat-h4 nowrap title-content p-title">
            {{
            product?.title && product?.title != ""
            ? product?.title
            : ("N/A" | translate)
            }}
          </h4>
          <div class="save-and-date">
            <div class="caption-md count-save">
              {{ "sellProductDetailPage.Save" | translate }} :
              {{ product?.favorites_count ?? ("N/A" | translate) }}
            </div>
            <div class="caption-md">|</div>
            <div class="date caption-md">
              {{ product?.created_at | date : "dd-MM-YYYY" }}
            </div>
          </div>
        </div>
        <div class="buttons" *ngIf="!data?.isMyOwnStore && !data.fromChat">
          <app-button-send-message [tab]="'shop'" [shop]="product?.shop?._id" [buttonSize]="'btn-sm'"
            [buttonType]="'flatBtn'" (click)="dialogRef.close()"></app-button-send-message>
          <button (click)="openRequestQuoteDialog(product)" mat-flat-button color="primary"
            class="btn-round message-btn">
            <mat-icon class="svg-path svg-fill" svgIcon="icon-suggestion"></mat-icon>
            <span>{{ "sellProductDetailPage.RequestPrice" | translate }}</span>
          </button>
          <button mat-icon-button [matMenuTriggerFor]="moreMenu" class="btn-round btn-light">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu class="option-menu opt-card-actions" #moreMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="openReportDialog()">
                <mat-icon svgIcon="alert-circle" class="svg-path svg-stroke"></mat-icon>
                {{ "Report.title" | translate }}
            </button>
            <button mat-menu-item *ngIf="!product?.is_favorite"
              (click)="onSave(product)">
              <mat-icon class="svg-path svg-fill" svgIcon="icon-shop-bookmark"></mat-icon>
              {{ 'Save' | translate }}
            </button>
            <button mat-menu-item *ngIf="product?.is_favorite"
              (click)="unsaved(product)">
              <mat-icon color="primary">done</mat-icon>
              {{ 'Unsave' | translate }}
            </button>
        </mat-menu>
        </div>
        <div *ngIf="data?.isMyOwnStore && !data?.fromChat" class="buttons">
          <button class="btn-edit btn-sm" type="button" mat-flat-button (click)="onEdit(product)">
            <mat-icon svgIcon="icon-edit-profile" class="edit-icon"></mat-icon>
            <span>{{ "Edit" | translate }}</span>
          </button>
          <button color="primary" mat-icon-button class="btn-round save" disableRipple [matMenuTriggerFor]="menu">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="option-menu opt-card-actions">
            <button mat-menu-item (click)="onSave(product)">
              <mat-icon *ngIf="product?.is_favorite; else elseBlock" color="primary">done</mat-icon>
              <ng-template #elseBlock>
                <mat-icon class="svg-path svg-fill" svgIcon="icon-shop-bookmark"></mat-icon>
              </ng-template>
              {{ (product?.is_favorite ? "Unsave" : "Save") | translate }}
            </button>
            <button mat-menu-item class="mat-button-danger" (click)="onDelete()">
              <mat-icon class="svg-path svg-fill" svgIcon="icon-delete"></mat-icon>
              {{ "Delete" | translate }}
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="save-and-date border-buttom">
        <div class="text-icon" *ngIf="!data.isMyOwnStore">
          <img src="assets/imgs/noun-quality.svg" />
          <div class="text-right" *ngIf="product?.qty">
            {{ product?.qty ?? ("N/A" | translate) }}
            {{ product?.unit?.symbol }}
          </div>
        </div>
        <div class="text-icon" *ngIf="data.isMyOwnStore">
          <img src="assets/imgs/noun-quality.svg" />
          <div class="text-right" *ngIf="product?.qty">
            {{ product?.qty ?? ("N/A" | translate) }}
            {{ product?.unit?.symbol }}
          </div>
        </div>
        <div class="text-icon border-left">
          <img src="assets/imgs/agronomy.svg" />
          <div class="text-right" *ngIf="data?.category_name; else elseBlock">
            {{
            data?.category_name && data?.category_name != ""
            ? data?.category_name
            : ("N/A" | translate)
            }}
            {{ data?.category_name || ("N/A" | translate) }}
          </div>
          <ng-template #elseBlock>
            <div class="text-right">
              {{ mapCategoryName(product?.categories) | commaJoinString }}
            </div>
          </ng-template>
        </div>
      </div>
      <mat-divider class="mb-0"></mat-divider>
      <div class="product-item-info">
        <div class="text-icon">
          <img src="assets/imgs/price-tag.svg" />
          <div class="price-number">
            {{ product?.prices | rangeCurrency : product?.currency?.symbol }}
          </div>
        </div>
      </div>
      <div class="description" *ngIf="product?.description">
        {{ product?.description }}
      </div>
      <h4 class="mat-h4 mb-0" *ngIf="product?.contact">
        {{ "Post.Advance" | translate }}
      </h4>
      <table>
        <tr class="text-icon">
          <th class="text-icon">
            <img src="assets/imgs/calling.svg" />
          </th>
          <td>
            {{ product?.shop?.contact.tels?.length > 0 ? product.shop.contact.tels : ("N/A" | translate) }}
          </td>
        </tr>
        <tr class="text-icon">
          <th class="text-icon">
            <img src="assets/imgs/location.svg" />
          </th>
          <td>
            {{ product?.contact?.province?.name ?? ("N/A" | translate) }}
          </td>
        </tr>
        <tr class="text-icon">
          <th class="text-icon">
            <img src="assets/imgs/address.svg" />
          </th>
          <td>
            {{ product?.shop?.contact?.address || ("N/A" | translate) }}
          </td>
        </tr>
      </table>
      <h4 class="mat-h4 mb-0">{{ "ProductPage.AboutShop" | translate }}</h4>
      <div class="text-icon">
        <img class="shop-profile"
          [src]="product?.shop?.photo_url ? (product?.shop?.photo_url | token) : 'assets/imgs/official-store.svg'" />
        <div *ngIf="product?.shop">
          <a class="general-link" [routerLink]="['/shop/shop-detail', product?.shop?._id]"
            (click)="dialogRef.close()">{{
            product?.shop?.name && product?.shop?.name != ""
            ? product?.shop?.name
            : ("N/A" | translate)
            }}</a>
        </div>
        <div *ngIf="!product?.shop">
          {{
          product?.shop?.name && product?.shop?.name != ""
          ? product?.shop?.name
          : ("N/A" | translate)
          }}
        </div>
      </div>
      <div *ngIf="data?.isMyOwnStore">
        <h4 class="mat-h4" *ngIf="chats.length > 0">
          {{ "Announcement.Contacter" | translate }}
        </h4>
        <div class="content-right-bottom" infiniteScroll [infiniteScrollDistance]="scrollDistance"
          [infiniteScrollThrottle]="throttle" [scrollWindow]="false" (scrolled)="onScroll()">
          <div *ngFor="let chat of chats">
            <div class="content-bottom">
              <div class="profile-content">
                <div class="profile" [routerLink]="['users/' + chat?.user?._id]" (click)="dialogRef.close()">
                  <img [src]="
                      chat?.user?.photo_url
                        | token : 'assets/imgs/img-no-gallery.svg'
                    " onerror="this.src='assets/imgs/img-no-gallery.svg'" />
                  <h4 class="shop-name">
                    {{ chat?.user | fullName : "" | uppercase }}
                  </h4>
                </div>
                <app-button-send-message [tab]="'shop'" [shop]="chat?.shop?._id" [buttonSize]="'btn-sm'"
                  [buttonType]="'flatBtn'" [isStoreMode]="true" (click)="dialogRef.close()"></app-button-send-message>
              </div>
              <div class="product-item-info">
                <div class="text-icon">
                  <img src="assets/imgs/noun-quality.svg" />
                  <div class="caption-md">
                    {{ "sellProductDetailPage.Quantity" | translate }} :
                  </div>
                </div>
                <div class="text-value">
                  {{ chat?.product?.request?.qty ?? ("N/A" | translate) }}
                  {{ chat?.product?.request?.unit?.symbol }}
                </div>
              </div>
            </div>
          </div>
          <div class="loading" *ngIf="isLoading" align="center">
            <mat-spinner [diameter]="50"></mat-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>